import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import 'babel-polyfill'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import 'lib-flexible/flexible'

import { Swipe, SwipeItem } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Field);
Vue.use(Button);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
