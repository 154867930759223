import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import {judgeBrower} from '@/utils'
const isPC = judgeBrower() === 'pc';

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => isPC ? import('@/pc/home.vue') : import('@/mobile/index.vue')
    },
    {
        path: '/introduce',
        name: 'introduce',
        component: () => isPC ? import('@/pc/introduce.vue') : import('@/mobile/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () =>isPC ?  import('@/pc/about.vue') : import('@/mobile/index.vue')
    },
    {
        path: '/relate',
        name: 'relate',
        component: () => isPC ? import('@/pc/relate.vue') : import('@/mobile/index.vue')
    }
]

const router = new VueRouter({
    routes,
    mode: 'hash'
})

export default router
